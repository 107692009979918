import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableData,
} from 'components/base-components/Table';
import { useDeleteSupplierCertificateMutation } from 'api/supplier-certificates';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { useGetCurrentUserQuery } from 'api/users';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import { useParams } from 'react-router-dom';
import EditForm from './edit/edit.component';
import CreateComplaint from './draft-declaration/create-complaint.component';
import ComplainModal from './shared/complain-modal.component';

const SupplierCertificateTableRow = ({
  certificateDeclarationsResponse,
  supplierCertificate,
  organizationId,
  showAction,
}) => {
  const { t } = useTranslation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const [deleteSupplierCertificate] = useDeleteSupplierCertificateMutation();
  const { complaint, declarationDate } = supplierCertificate;
  const { modifiable, status, id: declarationId } = certificateDeclarationsResponse || {};

  const isApproved = status === SUPPLIER_CERTIFICATE_TYPE.approved;

  const onDeleteSupplierCertificate = () => {
    const params = {
      organizationId,
      declarationId,
      certificateId: supplierCertificate.id,
    };

    deleteSupplierCertificate(params);
  };

  return (
    <Fragment>
      <TableRow className="supplier-certificates__row">
        <TableData>
          <div className="supplier-certificates__row-header">
            <span>{supplierCertificate.name}</span>
            {complaint && <ComplainModal complain={complaint} />}
          </div>
        </TableData>
        <TableData>{supplierCertificate.certificationIdentifier}</TableData>
        <TableData>
          {supplierCertificate.certificationDate
            ? dateMonthYearFormatter(supplierCertificate.certificationDate)
            : t('shared.tbd')}
        </TableData>
        <TableData>
          {declarationDate ? dateMonthYearFormatter(declarationDate) : t('shared.tbd')}
        </TableData>
        <TableData align="center">
          <a href={supplierCertificate.certificateUrl} target="_blank" rel="noreferrer">
            <IconButton
              className="supplier-certificates__row--download-icon"
              icon={<Icon name="downloadFile" size="small" />}
              color="default"
              disabled={!supplierCertificate.diplomaForEducationUrl}
              isIconOnly={true}
            />
          </a>
        </TableData>
        {!isApproved && showAction && (
          <TableData>
            <div className="supplier-certificates__row-actions">
              {isAdmin && (
                <CreateComplaint
                  selectedCertificate={supplierCertificate}
                  buttonLabel={t('supplier_certificates.complain')}
                  organizationId={organizationId}
                  declarationId={declarationId}
                  complainOnDeclaration={false}
                  showIconButton={true}
                />
              )}
              <EditForm
                organizationId={organizationId}
                supplierCertificate={supplierCertificate}
                declarationId={declarationId}
                disabled={!modifiable || isAdmin || supplierCertificate.isCopied}
              />
              <IconButton
                icon={<Icon name="delete" size="small" color="danger" />}
                color="danger"
                onClick={onDeleteSupplierCertificate}
                disabled={!modifiable || isAdmin}
              />
            </div>
          </TableData>
        )}
      </TableRow>
    </Fragment>
  );
};

SupplierCertificateTableRow.defaultProps = {
  showAction: true,
};

SupplierCertificateTableRow.propTypes = {
  supplierCertificate: PropTypes.shape().isRequired,
  organizationId: PropTypes.number.isRequired,
  certificateDeclarationsResponse: PropTypes.shape().isRequired,
  showAction: PropTypes.bool,
};

const SupplierCertificateTable = ({
  certificateDeclarationsResponse,
  supplierCertificates,
  showAction,
}) => {
  const { status } = certificateDeclarationsResponse || {};
  const isApproved = status === SUPPLIER_CERTIFICATE_TYPE.approved;
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      {supplierCertificates.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>{t('supplier_certificates.attributes.supplier_name')}</TableHeader>
              <TableHeader>{t('supplier_certificates.attributes.certificate_id')}</TableHeader>
              <TableHeader>{t('supplier_certificates.attributes.certification_date')}</TableHeader>
              <TableHeader>{t('supplier_certificates.attributes.declaration_date')}</TableHeader>
              <TableHeader align="center">
                {t('supplier_certificates.attributes.certificate')}
              </TableHeader>
              {!isApproved && showAction && (
                <TableHeader align="center">{t('shared.actions')}</TableHeader>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierCertificates.map(supplierCertificate => (
              <SupplierCertificateTableRow
                key={supplierCertificate?.id}
                certificateDeclarationsResponse={certificateDeclarationsResponse}
                supplierCertificate={supplierCertificate}
                showAction={showAction}
                organizationId={id}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('supplier_certificates.no_current_declaration')}
          emptyIcon={<Icon name="add" color="tertiary" />}
        />
      )}
    </>
  );
};

SupplierCertificateTable.defaultProps = {
  supplierCertificates: [],
  certificateDeclarationsResponse: null,
  showAction: true,
};

SupplierCertificateTable.propTypes = {
  supplierCertificates: PropTypes.arrayOf(PropTypes.shape()),
  certificateDeclarationsResponse: PropTypes.shape(),
  showAction: PropTypes.bool,
};

export default SupplierCertificateTable;
